export const Logo = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="139.601"
            height="156.439"
            viewBox="0 0 139.601 156.439"
        >
            <g
                id="Group_17"
                data-name="Group 17"
                transform="translate(-54.006 -93.693)"
            >
                <g
                    id="Group_1"
                    data-name="Group 1"
                    transform="translate(11.665 17)"
                >
                    <rect
                        id="Rectangle_3"
                        data-name="Rectangle 3"
                        width="128.481"
                        height="22.122"
                        transform="translate(96.081 227.132) rotate(-90)"
                        fill="#c0f"
                    />
                    <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="128.481"
                        height="22.122"
                        transform="translate(42.341 112.107) rotate(-16)"
                        fill="#c0f"
                    />
                </g>
                <g
                    id="Group_16"
                    data-name="Group 16"
                    transform="translate(21.665 23)"
                >
                    <rect
                        id="Rectangle_3-2"
                        data-name="Rectangle 3"
                        width="128.481"
                        height="22.122"
                        transform="translate(96.081 227.132) rotate(-90)"
                        fill="aqua"
                    />
                    <rect
                        id="Rectangle_2-2"
                        data-name="Rectangle 2"
                        width="128.481"
                        height="22.122"
                        transform="translate(42.341 112.107) rotate(-16)"
                        fill="aqua"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Logo;
